<template>
  <BasicLayout>
    <template v-slot:content>
      <h1 class="title">Ativação de conta</h1>

      <b-message v-if="errors.length" type="is-danger">
        <div v-for="error in errors" :key="error.key">
          <div v-if="error.key == 'InvalidRecoverPasswordCode'">
            O código de ativação não é válido.
          </div>
          <div v-else-if="error.key == 'MaxAttempts'">
            Foi ultrapassado o limite de tentativas.<br />Por favor, tente mais tarde.
          </div>
          <div v-else-if="error.key == 'AlreadyActivatedOrNotFound'">
            A conta já foi ativada.
          </div>
          <div v-else>
            <p>Não foi validar a sua nova conta.</p>
            <p>{{ error.message }}</p>
          </div>
        </div>
      </b-message>

      <div v-if="accountActivated" class="has-margin">
        <p class="is-size-3">A sua conta foi validada com sucesso.</p>
      </div>

      <b-button type="is-primary" expanded @click="$router.push('/login')">Entrar</b-button>
    </template>
  </BasicLayout>
</template>

<script>
import BasicLayout from '@/components/layouts/BasicLayout'
import AuthService from '@/services/AuthService'

export default {
  components: {
    BasicLayout,
  },

  data() {
    return {
      accountActivated: false,
      errors: [],
    }
  },

  created() {
    this.doConfirm()
  },

  methods: {
    doConfirm() {
      AuthService.activate(this.$route.params.hash)
        .then(() => {
          this.errors = [] // reset aos erros
          this.accountActivated = true
        })
        .catch(error => {
          this.errors = error.response.data.__errors__

          this.$buefy.snackbar.open({
            message: 'Ocorreu um erro',
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 4000,
          })

          // Garante que o utilizador vê o erro
          window.scrollTo(0, 0)
        })
    },
  },
}
</script>
